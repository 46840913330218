.r-modal{
    width: fit-content;
    height: fit-content;
    background-color: #FFF;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    padding: 50px;
    border-radius: 15px;

    &.fullscreen{
        width: 100dvw;
        height: 100dvh;
        background-color: #FFF;
        position: fixed;
        left: 0;
        top: 0;
        transform: translate(0, 0);
        z-index: 10000;
        padding: 50px 50px 0 50px;
        border-radius: 0;
    }

    &.pad-0{
        padding: 0;
    }
}



.r-modal-backdrop{
    width: 100vw;
    height: 100vh;
    background: rgba(186, 186, 186, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 19;
}