.react-datepicker-wrapper {
    width: 100%;
    
    .react-datepicker__input-container input {
        background-color: #f4f5f7;
        font-size: 15px;
        border: none;
        color: hsl(0, 0%, 20%);
        font-weight: 600;
        padding: 24px 16px;
    }
}