.team-management-page {
    .detailed-team-info-container{
        width: 100%;
        height: 100%;

        .action-btns{
            span:hover{
                cursor: pointer;
                opacity: 0.5;
            }
        }

        .tabs-container{
            width: fit-content;
            margin: 20px 0;

            .tab-item{
                padding: 5px 20px;
            }
        }

        .filters{
            .clear-filter-btn{
                margin-left: 60px;
            }
        }

    }
    .team-members-grid {
        .tabs-container{
            .tab-item{
                padding: 5px 20px;
            }
        }
    }
}

.swal2-container {
    z-index: 2000;
  }