//Generic content block styling
.button-section{
    display:flex;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 40px;

    .cancel-btn{
        font-size: 14px;
        cursor: pointer;
    }

    .action-btn, .action-btn:active{
        color: white !important;
        background: #242424 !important;
        border: transparent 1px solid !important;
    }

    .action-btn:hover{
        color: #242424 !important;
        background: #ffffff !important;
        border: #242424 1px solid !important;
    }
}

.file-error-card{
    padding: 20px;
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .header{
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }

    .error-content{
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding: 1rem;
    }

    .close-btn{
        cursor: pointer;
    }
}

.error-tag{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    svg{
        color: var(--red)
    }
}