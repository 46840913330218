.search-results{
    position: absolute;
    z-index: 25;
    left: 5rem;
    border: 1px solid #f7f7f7;
    border-radius: 10px;
    width: 50%;
    height: 180px;
    overflow: auto;
    margin-top: 10px;
    background-color: white;
    
    .headings{
        font-size: 15px;
        font-weight: 600;
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 20px 20px 0 20px;
    }

    .table-row{
        cursor: pointer;
        padding: 10px 20px;
        font-size: 15px;
        display: grid;
        grid-template-columns: 50% 50%;

        p{
            user-select: none;
            pointer-events: none;
            margin: 0;
        }
    }

    .table-row:hover{
        background-color: rgb(41, 105, 223);
        p{
            color: white;
        }
    }

    .no-result{
        width: 100%;
        padding: 10px 20px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        div{
            img{
                width: 100px;
            }
            span{
                width: 80%;
                display: inline-block;
                white-space: normal;
            }
        }
        
    }
}

