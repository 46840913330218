.file-upload-block{
    width: 40em;
    min-height: 200px;

    form{
        width: 100%;
        height: 100%;
    }

    .drag-drop-window{
        width: 100%;
        height: 100%;
        position: relative;
        border: 2px dashed rgb(134, 134, 134);

        .content{
            position: absolute;
            top: 50%;
            left:50%;
            gap: 10px;
            align-items: center;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            transform: translate(-50%, -50%);
        }

        .icon{
            width: 2em;
            height: 2em;
        }
    }
    
    .list{
        height: 350px;
        overflow: auto;

        .user-microcard{
            padding: 10px;
            border: 1px solid rgb(180, 180, 180);
            border-radius: 10px;
        }
    }

}


.searchBy-empNumber-block {
    width: 500px;

    .search-container{
        position: relative;

        .search-bar{
            display: flex;
            gap: 10px;

            :first-child{
                width: 100%;
            }
        }

        .search-results{
            position: absolute;
            top: 100%;
            z-index: 25;
            left: 0;
            border: 1px solid #f7f7f7;
            border-radius: 10px;
            width: 100%;
            height: 250px;
            overflow: auto;
            margin-top: 10px;
            background-color: white;
            
            .headings{
                font-size: 15px;
                font-weight: 600;
                display: grid;
                grid-template-columns: 50% 50%;
                padding: 20px 20px 0 20px;
            }

            .table-row{
                cursor: pointer;
                padding: 10px 20px;
                font-size: 15px;
                display: grid;
                grid-template-columns: 50% 50%;

                p{
                    user-select: none;
                    pointer-events: none;
                    margin: 0;
                }
            }

            .table-row:hover{
                background-color: rgb(41, 105, 223);
                p{
                    color: white;
                }
            }

            .no-result{
                width: 100%;
                padding: 10px 20px;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                div{
                    img{
                        width: 100px;
                    }
                    span{
                        width: 80%;
                        display: inline-block;
                        white-space: normal;
                    }
                }
                
            }
        }
    }

    .list{
        height: 250px;
        overflow: auto;

        .user-microcard{
            padding: 10px;
            border: 1px solid rgb(180, 180, 180);
            border-radius: 10px;
        }
    }

}

