.searchBy-campName-block{
    width: 500px;

    .search-container{
        position: relative;

        .search-bar{
            display: flex;
            gap: 10px;
        }

        .table-container{
            padding: 20px 0;

            table{
                overflow-y: auto;
            max-height: 300px;

            }
            th, td{
                font-size: 0.8rem;
            }
        }
    }
}

.searchBy-empNumber-block {
    width: 500px;

    .list{
        height: 250px;
        overflow: auto;

        .user-microcard{
            padding: 10px;
            border: 1px solid rgb(180, 180, 180);
            border-radius: 10px;
        }
    }

}