@keyframes topFadeIn {
	0% {  transform: translateX(0); }
	25% {  transform: translateX(-3px); }
	50% {  transform: translateX(3px); }
	100% { transform: translateX(0); }
}

.shake-animate{
    animation: topFadeIn 2s;
	animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}