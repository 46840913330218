// Components
@import "./components/ContentBlocks.scss";
@import "./components/Modals.scss";
@import "./components/User.scss";
@import "./components/Requestor.scss";
@import "./components/Campaign.scss";
@import "./packages/ReactDatePicker.scss";
// Pages
@import "./pages/TeamManagement.scss";
//Animations
@import "./animations/style.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
 * start: custom styles
 */

.new-request_placeholder-card:hover {
  cursor: pointer;
}