.popup {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1200;
    padding: 8%;
}

.popup-inner{
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 450px;
    min-height: 300px;
    background-color: #FFF;
    max-height: 450px;
    overflow: auto;
}

.popup-header{

    position: relative;
    max-width: 450px;
    height: 100px;
    z-index: 1200;
    padding: 32px;
    width: 100%;
    background-color: #FFF;
   
 
}

.popup-footer{
    position: relative;
    max-width: 450px;
    height: 80px;
    z-index: 1200;
    padding: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
 
}

.selected{
    border-width: medium;
    border-radius: 15px !important;
    border-color: rgb(52, 176, 247) !important;
    border-style: solid !important;
    padding-left: 10px;
}

.disabled{
    pointer-events:none
}

.save-btn{
    width: 100%;
    background-color: rgb(62, 172, 62) !important;
    color: white !important;;
}

.save-btn:disabled{
    width: 100%;
    background-color: rgb(192, 192, 192) !important;
    color: white !important;;
}

.container{
    width: 100%;
    max-width: 450px;
    background-color: #FFF;
    border-radius: 15px;
    padding: 15px;
}
