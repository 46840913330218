@import "./index.scss";

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Loader {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Loader-Result {
  justify-content: center !important;
  align-items: center !important;
  /* width: 100vw;
  height: 135vh !important; */
  position: relative;
}

.page-loader{
  height: 100vh;
  width: 100Vw;
  overflow: hidden;
  z-index: 10000000;
  background: #fff;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.component-loader{
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 10000;
  background: #ffffff9d;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-icon{
  outline: none;
  height: 150px;
  max-width: 100%;
  vertical-align: middle;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.select-control .custom-select__control {
  background-color: #F4F5F7;
  font-size: 14px;
  border: none;
}

.select-control .custom-select__control:hover {
  border-color: #282c34;
  box-shadow: 0 0 0 1px #282c34;
}

.select-control label,
.select-control small {
  display: block !important;
}

.select-control label {
  font-weight: bold;
  font-size: 15px;
}

.select-control small {
  font-weight: normal;
  font-size: 13px;
}

.select-control .custom-select__control .custom-select__value-container {
  padding: 10px;
}

.select-control .custom-select__control .custom-select__value-container .custom-select__multi-value label,
.select-control .custom-select__control .custom-select__value-container .custom-select__single-value label {
  font-weight: bold;
  /* display: block; */
}

.select-control .custom-select__control .custom-select__value-container .custom-select__single-value small {
  /* display: block; */
}

.select-control.select-with-html .custom-select__control .custom-select__value-container {
  padding: 25px;
}

.error{
  &.msg{
    color: red;
    font-size: 14px;

    &.pad{
      padding: 16px 10px;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}